<template>
  <div id="add-lead">
    <BaseModal
      name="ModalAdicionarLead"
      id="ModalAdicionarLead"
      idModal="ModalAdicionarLead"
      size="md"
      title="Cadastrar lead"
      @shown="openModal"
    >
      <div class="switch">
        <b-form-checkbox
          v-b-tooltip.hover
          title="Receber e-mail quando o lead enviar os dados"
          v-model="newLead"
          name="is_main"
          size="lg"
          switch
          class="switch6"
        >
        </b-form-checkbox>
        {{ newLead ? "Novo lead" : "Lead existente" }}
      </div>
      <b-tabs
        v-if="newLead"
        nav-class="TabHeaderAddLead"
        active-nav-item-class="TabHeader--active"
      >
        <b-tab
          :title="`Informações básicas`"
          active
          @click="typeList = 'Próximos'"
        >
          <div style="padding-bottom: 10px">
            <b-form-group label="Nome*">
              <b-form-input
                v-model="nome"
                name="nome"
                placeholder="Nome"
                v-validate="{ required: true, min: 6, max: 40 }"
              ></b-form-input>
            </b-form-group>
            <label for="E-mail" class="mt-3">E-mail</label>
            <input
              class="w-100"
              v-model="email"
              name="email"
              style="margin-bottom: 0 !important"
              placeholder="E-mail"
              v-validate="{ required: true, email: true }"
            />
            <label for="E-mail" class="mt-3">Telefone*</label>
            <VuePhoneNumberInput
              :translations="translations"
              :color="'#4ea934'"
              :maxLength="15"
              id="formattedNumber"
              @update="getNumber"
              v-model="telefone"
            />
          </div>
        </b-tab>
          <b-tab
          :title="`Endereço`"
          @click="typeList = 'Endereço'"
          v-if="leadsUnification"
        >
          <div class="grid-address-2">
            <div>
              <label>CEP</label>
              <input @input="debounceZipCode" v-mask="'#####-###'" type="text" v-model="zipCode" placeholder="CEP">
            </div>

            <div>
              <label>Estado</label>
              <input @input="validateFields($event, 'state')" type="text" v-model="state" placeholder="Estado">
            </div>
          </div>

          <div class="grid-address-3">
            <div>
              <label>Cidade</label>
              <input @input="validateFields($event, 'city')" type="text" v-model="city" placeholder="Cidade">
            </div>
            <div>
              <label>Bairro</label>
              <input @input="validateFields($event, 'neighborhood')" ref="neighborhood" type="text" v-model="neighborhood" placeholder="Bairro">
            </div>
            <div>
              <label>Número</label>
              <input v-mask="'###########'" type="text" v-model="number" placeholder="Número">
            </div>
          </div>

          <div>
            <label>Logradouro</label>
            <input @input="validateFields($event, 'street')" type="text" v-model="street" placeholder="Digite o logradouro">
          </div>
        </b-tab>
        <b-tab :title="`Mais opções`" @click="typeList = 'Pendentes'">
          <label for="E-mail">Vendedor</label>
          <router-link
            :to="{ name: 'Usuarios' }"
            v-if="
              !sellers.length && stops.includes('seller') && verificUserLevel
            "
            class="add-link"
            >Deseja criar um vendedor?</router-link
          >
          <multiselect
          v-model="vendedor"
          :options="sellers"
          class="w-100"
          label="email"
          track-by="id"
          placeholder="Pesquise pelo e-mail"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          :allow-empty="true"
          :searchable="true"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc select-seller-option">
                <div :style="hasPictureSeller(props.option) ? `background-image: url(${hasPictureSeller(props.option)}); color: transparent` : ''">{{ returnInitialSeller(props.option) }}</div>
                <div>
                  <p class="option__title">{{ props.option.first_name }} {{ props.option.last_name }}</p>
                  <p class="option__subtitle">{{ props.option.email }}</p>
                </div>
              </div>
            </template>
          </multiselect>

          <label class="mt-3">Empresa</label>
          <BaseSelect
            :selectModel="empresa"
            placeholder="Empresas"
            trackname="name"
            label=""
            track-by="id"
            :array="empresas"
            :multiple="false"
            :searchable="true"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :show-no-results="true"
            :hide-selected="true"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :loading="loadingCompany"
            @search="debounceCompany"
            @callback="getCompanies($event)"
            @change="empresa = $event"
            direction="top"
            ><span slot="noResult">Nenhum empresa encontrada.</span>
          </BaseSelect>

          <label class="mt-3">Formulário</label>
          <router-link
            :to="{ name: 'CampanhasLista' }"
            v-if="!forms.length && stop && verificUserLevel"
            class="add-link"
            >Deseja criar um formulário?</router-link
          >
          <BaseSelect
            :selectModel="formulario"
            label=""
            placeholder="Selecione o formulário"
            track-by="id"
            :array="forms"
            :multiple="false"
            :searchable="true"
            :taggable="true"
            class=""
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            @search="debounceForms($event)"
            :loading="loadingForms"
            @callback="getForms($event)"
            @change="formulario = $event"
            ><span slot="noResult">Nenhum formulário encontrado.</span>
          </BaseSelect>
        </b-tab>
      </b-tabs>
      <b-tabs
        v-else
        nav-class="TabHeaderAddLead"
        active-nav-item-class="TabHeader--active"
      >
        <b-tab
          :title="`Adicionar lead existente`"
          active
          @click="typeList = 'Próximos'"
        >
          <div class="search-lead">
            <img
              src="@/assets/img/icons/search.svg"
              class="PointersearchIcon"
            />
            <input
              type="text"
              placeholder="Pesquisar lead"
              v-model="searchLead"
              class="input-busca"
              @input="debounceSearch"
            />
          </div>
          <div class="leads-container">
            <div
              style="padding: 5px 10px !important"
              v-for="(item, index) in UserFilter"
              :key="index"
              class="leads-card"
              :style="
                item.id == selectedLead.id
                  ? 'background-color: var(--white-medium)'
                  : ''
              "
              @click="selectedLead = item"
            >
              <div style="display: flex; align-items: center">
                <div style="margin-right: 10px">
                  <div
                    v-if="
                      item &&
                      item.metas &&
                      item.metas.length &&
                      item.metas.find((x) => x.meta_key === 'photo-lead') &&
                      item.metas.find((x) => x.meta_key === 'photo-lead')
                        .meta_value
                    "
                  >
                    <img
                      style="margin: 0 !important"
                      class="seller-photo"
                      :src="
                        item.metas.find((x) => x.meta_key === 'photo-lead')
                          .meta_value
                      "
                      alt=""
                    />
                  </div>
                  <div v-else class="seller-no-ft" style="margin: 0 !important">
                    <h3 style="text-align: center">
                      {{
                        getMetaInitialLead(item.name ? item.name : item.email)
                      }}
                    </h3>
                  </div>
                </div>
                <span class="sellers-name">
                  <label style="margin: 0 !important" for="">{{
                    item.name ? item.name : "Sem nome"
                  }}</label>
                  {{ item.email }}
                </span>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" class="mr-4" @click="cancel">
          Fechar
        </BaseButton>
        <BaseButton
          variant="primary"
          @click="newLead ? onSubmit() : assignLead()"
          :disabled="loading"
        >
          Adicionar lead
        </BaseButton>
      </template>

      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import Multiselect from "../../../node_modules/vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import LeadService from "@/services/resources/LeadService";
const serviceLead = LeadService.build();

import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import MoverLeadsService from "@/services/resources/MoverLeadsService";
const serviceMoverLeads = MoverLeadsService.build();

import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();

import CompanyService from "@/services/resources/CompanyService";
const serviceCompanies = CompanyService.build();

import TagService from "@/services/resources/TagService";
const serviceTag = TagService.build();

import Vue from "vue";

import _ from "lodash";
export default {
  props: {
    filtros: Object,
    leads: Object,
    selectedPipeline: Number,
    valor_tag_colum: Number,
    all_leads: Object,
    stop: false,
    sellers: Array,
    changesTags: String,
    leadsUnification: Boolean
  },
  components: {
    Multiselect,
    VuePhoneNumberInput,
  },
  data() {
    return {
      //forms
      loadingForms: false,
      pageForm: 1,
      searchForm: "",
      stopForm: false,

      //company
      loadingCompany: false,
      pageCompany: 1,
      searchCompany: "",
      stopCompany: false,

      UserFilter: {},
      newLead: true,
      selectedLead: {},
      empresas: [],
      empresa: "",
      nome: "",
      email: "",
      telefone: "",
      search: "",
      searchLead: "",
      loading_select: false,
      antiDuplicate: "",
      formulario: null,
      tags: this.$store.getters.tags,
      forms: [],
      stops: [],
      loading: false,
      translations: {
        countrySelectorLabel: "",
        countrySelectorError: "",
        phoneNumberLabel: "",
        example: "",
      },
      vendedor: {email:"Selecione um vendedor"},
      zipCode: '',
      countryCode: '',
      state: '',
      city: '',
      neighborhood: '',
      street: '',
      number: ''
    };
  },
  watch: {
    telefone(newTelefone) {
      if (newTelefone && newTelefone.length > 17) {
        const newNumber = newTelefone.slice(0, 12);
        const lastDigit = newTelefone.slice(-1);
        this.telefone = newNumber.slice(0, -1) + lastDigit;
      }
    },
  },
  verificUserLevel() {
    var user = this.$store.getters.user.user.level;
    if (user === "owner" || user === "admin" || user == "gerent_seller") {
      return true;
    } else {
      return false;
    }
  },
  methods: {
    async openModal() {
      await this.ajustTab();
    },
    async ajustTab() {
      await this.$nextTick();
      if(!this.leadsUnification){
        const element2 = document.querySelector('.TabHeaderAddLead .nav-item a[aria-posinset="2"]');
        element2.style.borderRadius = '0 10px 0 0';
        element2.style.borderRight = '1px solid var(--white-medium)';
      }
    },
    validateFields(event, property) {
      this[property] = event.target.value.replace(/\d/g, '');
    },
    returnInitialSeller(user) {
      if (user.first_name && user.last_name) {
        return `${user.first_name[0]}${user.last_name[0]}`
      } else if (user.first_name) {
        return `${user.first_name[0]}${user.first_name[1]}`
      } else if (user.email) {
        return `${user.email[0]}${user.email[1]}`
      } else {
        return '??'
      }
    },
    hasPictureSeller(user) {
      let picMeta = user.metas?.find(meta => meta.meta_key === 'photo')
      if (picMeta) {
        return picMeta.meta_value
      } else {
        return ''
      }
    },
    debounceSearch: _.debounce(function () {
      this.getLead();
    }, 500),
    debounceForms: _.debounce(function (query) {
      if (query === "") {
        this.loadingForms = false;
      } else {
        this.searchForm = query;
        // this.forms = [];
        this.getForms(this.page, query);
      }
    }, 500),

    getForms(page = 1, query = "") {
      if (this.stopForm == true) {
        this.loadingForms = false;
        return;
      }
      var data = {
        id: `?page=${page}&order_by=title&status=active&order=ASC&search=${query}`,
      };
      serviceForm
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopForm = true;
          }
          this.forms = this.forms.concat(resp.data);
          this.forms = this.forms.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {});
    },

    // debounceForms: _.debounce(function (query) {
    //   if (query === "") {
    //     this.loadingForms = false;
    //   } else {
    //     this.searchForm = query;
    //     this.getForms(1, query);
    //   }
    // }, 500),

    debounceCompany: _.debounce(function (query) {
      if (query === "") {
        this.loadingCompany = false;
      } else {
        this.searchCompany = query;
        this.getCompanies(1, query);
      }
    }, 500),

    getCompanies(page = 1, query = "") {
      if (this.stopCompany == true) {
        return;
      }

      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}`,
      };

      serviceCompanies
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopCompany = true;
          }

          this.empresas = this.empresas.concat(resp.data);
          this.empresas = this.empresas.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingCompany = false;
        });
    },
    getNumber(e) {
      this.realNumber = e.formattedNumber;
    },
    saveLeadAddress(leadId, address){
      serviceLead
        .createId({id: `address/${leadId}`, ... address});
    },
    debounceZipCode: _.debounce(function (zipCode) {
      const code = zipCode.target.value.replace('-', '');
      if(code.length < 8) {
        return;
      }
      this.checkZipCode(code);
    }, 100),
    async checkZipCode(zipCode) {
      this.$refs.neighborhood.focus();
      const address = await this.getAddress(zipCode);
      if(address.erro) {
        this.$bvToast.toast("Endereço não encotrado. Por favor, confira o CEP", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;        
      }
      this.state = address.uf;
      this.city = address.localidade;
      this.neighborhood = address.bairro;
      this.street = address.logradouro;
      this.countryCode = address.uf;
    },
    async getAddress(zipCode) {
      return new Promise((resolve) => {
        fetch(`https://viacep.com.br/ws/${zipCode}/json`)
        .then((request) => request.json())
        .then((data) => {
          resolve(data);
        });
      });
    },
    onSubmit() {
      var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/g;

      this.loading = true;

      const regex = /  /;
      if (!this.nome || regex.test(this.nome)) {
        this.$grToast.toast("Insira o nome do lead", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      if (
        (!this.telefone || this.telefone.length < 10) &&
        (!this.email.match(emailRegex) || this.email.length < 4)
      ) {
        this.$grToast.toast("Insira um telefone ou um e-mail válido", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      if (this.formulario && this.formulario.id) {
        var data = {
          name: this.nome,
          email: this.email,
          company_id: this.empresa.id,
          phone:
            typeof this.telefone == "string"
              ? this.telefone.replace(/\D+/gi, "")
              : "",
          form_id: this.formulario.id,
          origin: "Cadastro manual",
          user_id: this.vendedor && this.vendedor.id ? this.vendedor.id : null,
        };
      } else {
        var data = {
          name: this.nome,
          email: this.email,
          company_id: this.empresa.id,
          phone:
            typeof this.telefone == "string"
              ? this.telefone.replace(/\D+/gi, "")
              : "",
          origin: "Cadastro manual",
          user_id: this.vendedor && this.vendedor.id ? this.vendedor.id : null,
        };
      }

            
      const address = {
        zipCode: this.zipCode,
        countryCode: this.countryCode,
        state: this.state,
        city: this.city,
        neighborhood: this.neighborhood,
        street: this.street,
        number: this.number
      }
      
      const addressFilled =  Object.values(address).every(valor => valor !== null && valor !== undefined && valor !== '');

      if(address.zipCode.length && !addressFilled){
        this.$bvToast.toast("Preencha todos os campos de endereço do lead", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      serviceLead
        .create(data)
        .then((response) => {
          this.assocCrm(response);
          if(addressFilled){
            this.saveLeadAddress(response.lead.id, address);
          }
        })
        .catch((err) => {
          const response = err.response;
          if(response && response.status && response.status === 400){
            this.$grToast.toast(`O lead está associado(a) ${response.data && response.data.seller_name ? 'ao(à) vendedor(a) ' + response.data.seller_name : 'a um vendedor'}`, {
              title: "Cadastro manual",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            setTimeout(() => {
              this.displayNotifySwal(response.data.lead_id);
            }, 1000);
            return;
          }

          this.$grToast.toast("Erro ao cadastrar Lead", {
            title: "Cadastro manual",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.clearFields();
          this.$bvModal.hide("ModalAdicionarLead");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    displayNotifySwal(leadId){
      Vue.swal({
          title: "Cadastro manual",
          text: `Deseja notificar o(a) vendedor(a)?`,
          type: "danger",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: "sweet-container",
          confirmButtonClass: "button button-primary mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            serviceLead
              .createId({id: `notify-attempt/${leadId}`})
              .then(() => {
                this.$grToast.toast(`Vendedor(a) será notificado(a)`, {
                title: "Cadastro manual",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
          }
        }); 
    },
    assocCrm(response){
      let columID = this.valor_tag_colum
      let data = {
        id: `${response.lead.id}/${this.selectedPipeline}/${columID}`,
      };

      servicePipeline.updateId(data)
      .then((resp) => {
        if (this.changesTags == "true") {
          var data = {
            id: "/assoc",
            leads: [response.lead.id],
            tag_id: columID,
          };
          serviceTag
            .createId(data)
            .then(() => {})
            .catch(() => {});
        }
        this.$grToast.toast("Lead cadastrado com sucesso", {
          title: "Cadastro manual",
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.$emit("atualizar");
        this.clearFields();
        this.$bvModal.hide("ModalAdicionarLead");
      })
    },
    clearFields(){
      this.nome = "";
      this.email = "";
      this.telefone = "";
      this.formulario = "";
      this.empresa = "";
      this.zipCode =  '';
      this.countryCode =  '';
      this.state =  '';
      this.city =  '';
      this.neighborhood =  '';
      this.street =  '';
      this.number =  '';
    },
    getMetaInitialLead(user) {
      if (user) {
        var name = user.split(" ")[0];
        var sobrenome = user.split(" ")[1] ?? undefined;
        if (!name && !sobrenome) {
          return lead.email.substring(0, 2);
        }
        if (!sobrenome) {
          return name.substring(0, 2);
        }
        return name[0] + sobrenome[0];
      }
      return;
    },
    getLead() {
      let data = {
        id: "/list",
        filters: {
          status: "active",
          search: this.searchLead,
          board: [],
          campanha: [],
          company: [],
          company_view: "",
          tag: [],
          tag_exceto: [],
          email: [],
          status_email: "",
          formulario: [],
          vendedor: [],
          funil: [],
          leadscore_minimo: "",
          leadscore_maximo: "",
          status_jornada: [],
          campos_adicionais: [],
          data_inicio: "",
          data_fim: "",
          acao_email_tempo: "",
          higiene: "",
          campo_data: "register",
          data_inicial: null,
          data_final: null,
          pipeline: [],
          vl_liquido: "",
        },
        leads: [],
        order: "DESC",
        orderby: "register",
        page: "",
        pagina: "",
        total_pagina: "",
        withs: [
          "Tags",
          "Products",
          "Forms",
          "Situations",
          "Companies",
          "Views",
        ],
      };
      serviceLead.createId(data).then((response) => {
        this.UserFilter = response.data;
      });
    },
    assignLead() {
      let columID = this.valor_tag_colum
      if (!this.selectedLead.id) {
        this.$grToast.toast("Selecione um lead", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      servicePipeline
        .update(
          `${this.selectedLead.id}/${this.selectedPipeline}/${columID}`
        )
        .then(() => {
          if (
            this.changesTags == "true" &&
            this.selectedLead.tags.every(
              (tag) => tag.id != columID
            )
          ) {
            var data = {
              id: "/assoc",
              leads: [this.selectedLead.id],
              tag_id: columID,
            };
            serviceTag
              .createId(data)
              .then(() => {})
              .catch(() => {
                this.$grToast.toast("Erro ao adicionar tag", {
                  title: "Lead",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              });
          }
          this.$emit("atualizar");
          this.$bvModal.hide("ModalAdicionarLead");
        })
        .catch(() => {
          this.$grToast.toast("Erro ao adicionar lead no board", {
            title: "CRM",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("ModalAdicionarLead");
        });
    },
  },
  
  async mounted() {
    await this.getLead();
  }
};
</script>

<style scoped lang="scss">
#ModalAdicionarLead {
}

.switch {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
  .switch6 {
    position: relative;
    bottom: 5px;
  }
}
.search-lead {
  position: relative;
  .input-busca {
    width: 100%;
    padding-left: 47px !important;
  }
  .PointersearchIcon {
    position: absolute;
    top: 17px;
    left: 17px;
  }
}

.leads-container {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 15px;
  height: 300px;
  overflow-y: auto;
}
.leads-card {
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 12px;
  height: fit-content;
  position: relative;
}
.seller-photo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-top: 11px;
  object-fit: cover;
}
.seller-no-ft {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: var(--greenn-transparent);
  color: var(--greenn) !important;
  border: 1px var(--greenn) solid;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  transition: all 0.3s;

  h3 {
    color: var(--greenn);
    margin: 0 !important;
    padding: 0 !important;
    font-size: 16px;
  }
}

.seller-no-ft:hover {
  background: var(--greenn2);
}
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
<style lang="scss">
#ModalAdicionarLead___BV_modal_body_ {
  max-height: 500px;
  min-height: 500px;
  .tab-content > .tab-pane {
    border: 1px solid var(--white-medium) !important;
    border-radius: 0 10px 10px;
    padding: 20px;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--greenn) !important;
    font-weight: 500 !important;
  }
  .nav-tabs {
    font-weight: 500 !important;
    border-bottom: none !important;
  }

  .TabHeaderAddLead .nav-item a {
    padding: 10px 15px !important;
    color: var(--gray05);
    // margin-right: 1px;
    width: fit-content !important;
    border-radius: 0;
    border: 1px solid var(--white-medium) !important;
    border-right: none !important;
    transition: all 0.3s;
    font-weight: 500 !important;
    font-size: 15px !important;
    &:hover {
      color: var(--gray05);
    }
  }

  .TabHeaderAddLead .nav-item a.active {
    border-bottom-color: var(--white) !important;
    background-color: var(--white);
  }

  .TabHeaderAddLead .nav-tabs .nav-link:hover {
    border: 1px solid var(--white-medium) !important;
  }
  .TabHeaderAddLead .nav-item a[aria-posinset="1"] {
    border-radius: 10px 0 0 0 !important;
  }

  .TabHeaderAddLead .nav-item a[aria-posinset="3"] {
    border-radius: 0 10px 0 0 !important;
    border-right: 1px solid var(--white-medium) !important;
  }

  .grid-address-2, .grid-address-3 {
    display: grid;
    gap: 10px;
    margin-bottom: 10px;
  }
  .grid-address-2 {
    grid-template-columns: 1fr 100px;
  }
  .grid-address-3 {
    grid-template-columns: 1fr 150px 105px;
  }
  input {
    width: 100%;
  }
}
</style>
